/*
* 主要用于记录faq中所有的问题。
*/

// 英文页面的FAQ
const EnglishList = [
  {
    id: 'q1',
    desc: 'Where can I download U-Dictionary?',
    show: false,
  },
  {
    id: 'q2',
    desc: 'Does U-Dictionary have offline translation?',
    show: false,
  },
  {
    id: 'q3',
    desc: 'The app always breaks down or forces me to quit. What should I do?',
    show: false,
  },
  {
    id: 'q4',
    desc: 'When I use camera translation I always get another language than the target language I have expected. What should I do?',
    show: false,
  },
  {
    id: 'q5',
    desc: 'How can I turn the lock screen off? ',
    show: false,
  },
  {
    id: 'q6',
    desc: 'The subscription has been canceled and the amount has not been returned ( IOS )',
    show: false,
  },
];

// 西班牙语的FAQ页面
const SpanishList = [
  {
    id: 'q1',
    desc: 'Mi fondo de pantalla cambió',
    show: false,
  },
  {
    id: 'q2',
    desc: 'La traducción por cámara no funciona.',
    show: false,
  },
  {
    id: 'q3',
    desc: 'Sólo traduce la mitad del texto por cámara.',
    show: false,
  },
  {
    id: 'q4',
    desc: '¿Cómo activar el Traductor Mágico?',
    show: false,
  },
  {
    id: 'q5',
    desc: '¿Cómo usar el Traductor Mágico?',
    show: false,
  },
  {
    id: 'q6',
    desc: 'No puedo usar el Traductor Mágico',
    show: false,
  },
  {
    id: 'q7',
    desc: 'Mi pantalla se volvió Negra',
    show: false,
  },
  {
    id: 'q8',
    desc: 'El texto de mi celular se amplió',
    show: false,
  },
  {
    id: 'q9',
    desc: 'El color de mi celular cambió.',
    show: false,
  },
  {
    id: 'q10',
    desc: 'Dí los permisos para usar el Traductor Mágico pero se desactivan.',
    show: false,
  },
  {
    id: 'q11',
    desc: 'La app no traduce.',
    show: false,
  },
  {
    id: 'q12',
    desc: '¿Cómo cambiar el idioma a traducir del Traductor Mágico?',
    show: false,
  },
  {
    id: 'q13',
    desc: '¿Cómo cerrar el ícono flotante del Traductor Mágico?',
    show: false,
  },
  {
    id: 'q14',
    desc: 'Cómo usar la app sin conexión a internet.',
    show: false,
  },
  {
    id: 'q15',
    desc: 'Tengo muchas notificaciones de Traductor U.',
    show: false,
  },
  {
    id: 'q16',
    desc: ' Quiero cambiar el idioma de la app.',
    show: false,
  },
  {
    id: 'q17',
    desc: ' ¿Cómo traduzco a otro idioma que no sea inglés?',
    show: false,
  },
  {
    id: 'q18',
    desc: '¿Cómo quito los anuncios?',
    show: false,
  },
  {
    id: 'q19',
    desc: '¿Cómo reporto un problema?',
    show: false,
  },
  {
    id: 'q20',
    desc: '¿Traductor U guarda mis datos?',
    show: false,
  },
];

// 印尼语的FAQ
const IndonesianList = [
  {
    id: 'q1',
    desc: 'Aku mengalami kendala dalam penggunaan U-Dictionary, nih. Aku harus gimana ya?',
    show: false,
  },
  {
    id: 'q2',
    desc: 'Aku udah tau nih gimana cara pakai U-Dictionary, tapi aku ga tau gimana cara aktifkan fitur Magic Translate nih?',
    show: false,
  },
  {
    id: 'q3',
    desc: 'Aku sudah aktifkan Magic Translate, tapi sekarang Handphone aku jadi suka ngomong sendiri nih?',
    show: false,
  },
  {
    id: 'q4',
    desc: 'Aku sering pakai fitur Magic Translate di U-Dictionary tapi kok ada limitnya ya?',
    show: false,
  },
  {
    id: 'q5',
    desc: 'Aku mau pakai U-Dictionary secara offline, kok ga bisa sih?!',
    show: false,
  },
  {
    id: 'q6',
    desc: 'Aku mau pakai Camera Translate (Kamera Penerjemah) kenapa kok ga akurat ya?',
    show: false,
  },
  {
    id: 'q7',
    desc: 'Aku kok ga bisa pake Magic Translate lagi ya? Padahal kemarin masih bisa-bisa aja.',
    show: false,
  },
  {
    id: 'q8',
    desc: 'Kok aku keluar dari U-Dictionary dan Magic Translate juga ikut tertutup?',
    show: false,
  },
  {
    id: 'q9',
    desc: 'Aku mau tau dong sebenarnya U-Dictionary itu memakan ruang seberapa besar sih di ponsel aku?',
    show: false,
  },
];

const ArabicList = [
  {
    id: 'q1',
    desc: ' ما هو يو ديكشناري؟',
    show: false,
  },
  {
    id: 'q2',
    desc: 'هل احتاج لإتصال بشبكة انترنت لإستخدام تطبيق يو ديكشناري؟',
    show: false,
  },
  {
    id: 'q4',
    desc: 'كيف يُمكن تفعيل الترجمة السحرية؟',
    show: false,
  },
  {
    id: 'q5',
    desc: 'لماذا الترجمة السحرية لا تعمل ويظهر ان ادفع اشتراك مقدما؟',
    show: false,
  },
  {
    id: 'q6',
    desc: ' كيفية ترجمة محاداثات الواتس اب؟',
    show: false,
  },
  {
    id: 'q7',
    desc: 'لماذا الترجمة السحرية لا تعمل احيانا؟',
    show: false,
  },
  {
    id: 'q8',
    desc: 'كيف استخدم ترجمة الكاميرا؟',
    show: false,
  },
  {
    id: 'q9',
    desc: 'لماذا لا أجد ترجمة الأشياء فى أداة ترجمة الكاميرا؟',
    show: false,
  },
  {
    id: 'q10',
    desc: ' كيف أٌبلغ عن خطأ في تطبيق يو ديكشناري؟',
    show: false,
  },
  {
    id: 'q11',
    desc: ' كيف اقوم بحذف الإعلانات على تطبيق يو ديكشناري؟',
    show: false,
  },
  {
    id: 'q12',
    desc: ' كيف أقوم بفتح الأوذونات فى تطبيق يو ديكشناري؟',
    show: false,
  },
  {
    id: 'q13',
    desc: ' كيف يمكني تغير اللغة فى الترجمة الصوتية؟',
    show: false,
  },
  {
    id: 'q14',
    desc: 'كيف اوقف شاشة غلق الكلمة؟',
    show: false,
  },
]

const langWithQuestions = {
  English: EnglishList,
  Español: SpanishList,
  Bahasa: IndonesianList,
  العربية: ArabicList,
}

export default {
  langWithQuestions,
};
