<template lang="pug">
.contact-footer
  .box
    .contact_panel
      a(href="mailto:udictionary01@gmail.com")
        img.email(src='@/assets/images/home/contact_icon-email.png')
      a(href="https://www.facebook.com/UDictionary")
        img.facebook(src='@/assets/images/home/contact_icon-facebook.png')
      a(href="https://www.instagram.com/udictionary_eng/")
        img.ins(src='@/assets/images/home/contact_icon-ins.png')
      a(href="https://www.youtube.com/channel/UCJPIDjpTCVoc94Ypy47NryA")
        img.youtube(src='@/assets/images/home/contact_icon-youtube.png')
    .download_panel
      download-icon(size="mini")
</template>
<script>
import downloadIcon from '@/components/pc/downloadIcon.vue';

export default {
  components: { downloadIcon },
  data() {
    return {

    };
  },
}
</script>
<style lang="stylus" scoped>
.contact-footer
  width 100%
  min-width 1300px
  height 64px
  background #F5F5F7
  .box
    width 1300px
    margin 0 auto
    display flex
    justify-content center
    .contact_panel
      margin 20px 800px 0 0
      .email
        margin-right 30px
        width 15px
      .twitter
        margin-right 30px
        width 16px
      .facebook
        width 11px
        margin-right 30px
        transform translateY(4px)
      .ins
        width 19px
        margin-right 30px
        transform translateY(5px)
      .youtube
        width 21px
        transform translateY(2px)
    .download_panel
      margin-top 20px
</style>
