<template lang="pug">
div(:class="[lang, device ? device : 'faq_page']")
  .faq_box
    nav-bar(v-if="!show")
    .content
      img.pic(v-if="!show" src='@/assets/images/faq/mainPic.png')
      img.pic(v-if="show" src='@/assets/images/mobile/faq.png')
      p.title(v-if="!show") {{$TT('Question Category')}}
      p.pic_title(v-if="show") {{$TT('Question Category')}}
    ul.question_list
      li.question(
        v-for="(item, index) in questionList"
        :key="index"
        @click="renderAnswer(item)"
      )
        .display_panel
          h3.text {{item.desc}}
          img.arrow(src="@/assets/images/faq/arrow.png")
        .answer_panel(
          v-if="item.show"
          :class="item.id"
          style="line-height: 30px; space-lettering: 0.7px"
        )
    .help_panel
      p.title {{$TT('Did not find the answer? Email us')}}
      .email
        a.link(href="mailto:udictionary01@gmail.com") udictionary01@gmail.com
        img.email_arrow(src="@/assets/images/faq/email_arrow.png")
    contact-footer.contact(v-if="!show")
    dict-footer.footer(v-if="!show")
</template>

<script>
import navBar from '@/components/pc/navBar.vue';
import dictFooter from '@/components/pc/dictFooter.vue';
import contactFooter from '@/components/pc/contactFooter.vue';
import service from '@/config/faq/questions';
import data from '@/config/faq/detail';
import connectdata from '@/config/contact';
import { createDomNode, getLangClass } from '@/utils/index';
import question from './components/question.vue';

export default {
  name: 'FAQ',
  components: {
    navBar,
    dictFooter,
    question,
    contactFooter,
  },
  props: ['device'],
  data() {
    return {
      questionList: [],
      deatil: {},
      show: true,
      lang: '',
      emailAddress: '',
    }
  },
  // 针对移动端的适配进行修改
  watch: {
    device: {
      handler(val) {
        if (val === 'mobile') {
          this.show = true;
        } else {
          this.show = false;
        }
      },
      immediate: true,
    },
    '$store.state.selectLang': {
      handler(val) {
        this.questionList = service.langWithQuestions[val];
        this.deatil = data.langAnswer[val];
        this.lang = getLangClass(val);
        this.emailAddress = connectdata.contactInfo[val];
        if (this.questionList) {
          this.questionList.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.show = false
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let lang = '';
    if (this.device === 'mobile') {
      lang = 'English';
    } else {
      lang = this.$store.state.selectLang;
    }
    this.lang = getLangClass(lang);
    this.questionList = service.langWithQuestions[lang];
    this.deatil = data.langAnswer[lang];
    if (this.device !== 'mobile') {
      this.log('ud_web_download_button_view')
    }
  },
  methods: {
    renderAnswer(content) {
      if (!content.show) {
        /* eslint-disable-next-line */
        content.show = true;
        setTimeout(() => {
          createDomNode(content, this.deatil);
        }, 10);
      } else {
        /* eslint-disable-next-line */
        content.show = false;
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.faq_page
  font-family Gilroy
  width 100%
  min-width 1300px
  .faq_box
    position relative
    .content
      padding-top 62px
      .pic
        height 527px
        width 100%
      .title
        position absolute
        top 238px
        left 50%
        transform translateX(-50%)
        color #FFF
        font-size 32px
        line-height 39px
        width 400px
        height 39px
        text-align center
        .bold
          font-weight bold
        .text
          font-weight 300
          margin-left 15px
    .question_list
      transform translateY(-130px)
      margin-top 450px
      width 1120px
      margin 0 auto
      background #fff
      border 1px solid #CACACA
      border-radius 20px
      .question
        .display_panel
          height 100px
          width 90%
          margin-left 4%
          display flex
          justify-content space-between
          align-items center
          .text
            font-weight 600
            font-size 20px
            line-height 24px
            color #061C32
          .arrow
            height 7px
            width 14px
        .answer_panel
          background rgba(224,224,224,0.2)
          min-height 122px
          padding  34px 100px 0 64px
          color #061C32
          font-size 18px
          line-height 21px
          border-top 1px solid #CACACA
          border-bottom 1px solid #CACACA
    .help_panel
      display flex
      flex-direction column
      align-items center
      transform translateY(-20px)
      .title
        font-size 16px
        color #061c32
        line-height 19px
        opacity 0.5
        margin-bottom 20px
      .email
        .link
          color #4762F2
          font-size 18px
          line-height 22px
          text-decoration underline
          font-weight bold
        .email_arrow
          margin-left 10px
          width 8px
    .contact
      margin-top 64px
      border-bottom 1px solid #F5F5F7
.mobile
  width 100%
  .faq_box
    position relative
    width 100%
    .pic
      width 100%
    .pic_title
      position absolute
      top 0.9rem
      left 50%
      transform translateX(-50%)
      color #FFF
      font-size 0.2rem
      text-align center
      width 100%
  .question_list
    width 100%
    .question
      .display_panel
        height 0.63rem
        width 88%
        display flex
        justify-content space-between
        align-items center
        margin-left 0.2rem
        .text
          line-height 0.17rem
          font-size 0.14rem
        .arrow
          width 0.1rem
          margin-left 0.2rem
      .answer_panel
        background rgba(224,224,224,0.2)
        padding 0 0.24rem
        font-size 14px
        line-height 0.16rem
        color #061c32
  .help_panel
    display flex
    flex-direction column
    width 80%
    margin 0.2rem auto
    text-align center
    .title
      width 3rem
      margin 0 auto
      font-size 12px
      line-height 0.14rem
      color rgba(6,28,50,0.5)
    .email
      width 2.0rem
      margin 0.1rem auto
      .link
        color #4762f2
        font-size 12.6px
        line-height 0.15rem
        text-decoration-line underline
      .email_arrow
        width 0.05rem
        margin-left 0.1rem
.ar
  .answer_panel
    direction rtl !important
    text-align right !important
  .question_list
    .question
      .display_panel
        flex-direction row-reverse
        .text
          text-align right
        .arrow
          margin-left 10px
</style>
