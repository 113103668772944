/* 英文FAQ对应的答案
* 通过 DOM 节点的创建。
*/
const EnglishAnswer = {
  q1: [
    ['span', 'You could find our apps in Google Play or Apple store for IOS. You’ll also be able to download the apk directly from our website: '],
    ['a', 'https://www.u-dictionary.com/'],
  ],
  q2: [
    ['span', 'After downloading the offline dictionary, you can translate words between your language and English offline.'],
  ],
  q3: [
    ['p', 'Hi there. This problem has been tracked and fixed by Google. You could now fix it by either:'],
    ['p', '1. updating your Chrome to the latest version.'],
    ['p', '2. or updating the WebView for Android to the latest version or uninstalling it: go to my apps -> installed apps -> on this devices --> find "Android System Web View" -> update or uninstall it.'],
    ['img', require('@/assets/images/faq/q3.png')],
  ],
  q4: [
    ['p', 'Try to turn your phone upside down. Holding the phone horizontally may cause some recognition issues and language problems.'],
  ],
  q5: [
    ['p', 'You can turn off the word lock screen in the app\'s settings.'],
  ],
  q6: [
    ['p', 'Hi there. You can easily cancel the subscription to avoid further charges anytime. After cancelling the subscription, you will not get charged anymore starting from a new pay cycle.'],
    ['p', 'All charges and transactions are conducted directly by Apple Pay under good protection. However, you will not be refunded by Apple for what you have paid before the cancellation taking place.'],
  ],
};

// 西班牙的FAQ答案
const SpanishAnswer = {
  q1: [
    ['p', 'Tienes activada la función de vocabulario en pantalla bloqueada, la cual te ayuda a aprender vocabulario diariamente. Para desactivarla y volver a tu fondo de pantalla normal, sólo abre Traductor U, da clic en el ícono inferior de ajustes, después selecciona Vocabulario en Pantalla Bloqueada, y da clic en el ícono superior derecho para desactivar la función.'],
  ],
  q2: [
    ['p', 'Abre Traductor U y da clic en cámara, selecciona en la parte superior el idioma a traducir y el idioma del resultado. Toma la foto dando clic al botón rojo, asegúrate de que el texto salga de forma clara en la foto.'],
    ['span', 'Después selecciona el área de la traducción y da clic en traducir. El resultado aparecerá en el recuadro, desliza hacia abajo para leer toda la traducción en caso de que el texto sea muy largo. Si el problema persiste, por favor manda un email con el screenshot a '],
    ['a', 'mailto:Udictionary01@gmail.com'],
  ],
  q3: [
    ['p', 'Una vez tomada la foto, selecciona el texto a traducir, si el texto es largo, la traducción aparecerá dentro de una caja la cual puede deslizarse hacia abajo para leer toda la traducción.'],
  ],
  q4: [
    ['p', 'Abre Traductor U y da clic en Mágico, se abrirá una ventana, da clic en "Mostrar sobre otras aplicaciones", encuentra "Traductor U" o "U-Dictionary" en la lista y activa el permiso.'],
    ['p', 'Da clic en volver para regresar a la app, después da clic en "Accesibilidad". NO actives Talkback ni Ampliación.  Da clic en Servicios descargados y encuentra "Traductor U" o "U-Dictionary" en la lista y activa el permiso. Listo, puedes usar el Traductor Mágico.'],
  ],
  q5: [
    ['p', 'Una vez dados los permisos, aparecerá una nube flotante con el logo U, sólo desliza la nube flotante al texto que quieras traducir, espera un momento y listo, la traducción aparecerá.'],
    ['p', 'Si tienes algún problema con la traducción o la función, por favor toma un screenshot y envíalo al email'],
    ['a', 'mailto:Udictionary01@gmail.com'],
    ['span', ' explicando a detalle el problema para que podamos solucionarlo a la brevedad.'],
  ],
  q6: [
    ['p', 'Si has excedido el límite diario de traducciones, puedes suscribirte a la versión PRO para disfrutar de traducciones ilimitadas y libres de anuncios. Si el problema es distinto, por favor manda un email a Udictionary01@gmail.com con el screenshot del problema.'],
    ['span', 'Si el problema es distinto, por favor manda un email a '],
    ['a', 'mailto:Udictionary01@gmail.com'],
    ['span', ' con el screenshot del problema.'],
  ],
  q7: [
    ['p', 'Es probable que diste un permiso equivocado, abre los ajustes de tu celular, encuentra accesibilidad y desactiva TalkBack.'],
  ],
  q8: [
    ['p', 'Es probable que diste un permiso equivocado, abre los ajustes de tu celular, encuentra accesibilidad y desactiva Ampliar.'],
  ],
  q9: [
    ['p', 'Es probable que diste un permiso equivocado, abre los ajustes de tu celular, encuentra accesibilidad ysi están activadas alguna de ls opciones "invertir colores" o "corrección de color", desactívalas.'],
  ],
  q10: [
    ['span', 'Intenta dar los permisos de "Mostrar sobre otras aplicaciones" y "Accesibilidad" nuevamente y después reinicia tu celular y abrir Traductor U. Si el problema persiste, por favor manda un email con el screenshot a '],
    ['a', 'mailto:Udictionary01@gmail.com'],
  ],
  q11: [
    ['span', 'Por favor mándanos un email a '],
    ['a', 'mailto:Udictionary01@gmail.com'],
    ['p', ' con el screenshot del problema y explica qué función intentabas usar (traductor de foto, voz, texto, mágico), el idioma que querías usar y el problema que tuviste, de esa forma podremos solucionarlo.'],
  ],
  q12: [
    ['p', 'Da clic en la nube flotante del Traductor mágico, aparecerá un menú arriba, da clic en Idioma y cambia el idioma a traducir.'],
  ],
  q13: [
    ['p', 'Da clic en la nube flotante del Traductor mágico, aparecerá un menú arriba, da clic en cerrar.'],
  ],
  q14: [
    ['p', 'Abre Traductor U, da clic en el ícono inferior de ajustes, selecciona la opción "Paquete sin internet", en la parte superior en rojo selecciona el idioma que quieres descargar, después da clic en la flecha hacia abajo que aparece a un lado de "Traductor Sin Internet", puedes descargar también diccionarios y más.'],
  ],
  q15: [
    ['p', 'Abre Traductor U, da clic en el ícono inferior de ajustes, selecciona "Configuración", desactiva "Traducción en barra de notificaciones" y "Notificación".'],
  ],
  q16: [
    ['p', 'Si quieres cambiar el idioma de la interfaz de la app, abre Traductor U, da clic en el ícono inferior de Ajustes y selecciona "Idioma de pantalla", después selecciona el idioma que quieras.'],
  ],
  q17: [
    ['p', 'Abre Traductor U, en la parte superior da clic a cualquier idioma para que se abra la lista de idiomas disponibles, selecciona el idioma a traducir y el idioma en el que quieres el resultado.'],
  ],
  q18: [
    ['p', 'Puedes suscribirte a la versión PRO de Traductor U, sólo abre la app, da clic en el ícono inferior de ajustes y selecciona "Prueba gratis" en el botón rojo superior, puedes suscribirte a la versión PRO.'],
  ],
  q19: [
    ['span', 'Envía un email a '],
    ['a', 'mailto:Udictionary01@gmail.com'],
    ['span', ' con el screenshot del problema y los detalles del mismo, como, función que tiene el problema (traductor de foto, voz, texto, mágico, etc), cuál es el problema y qué idioma intentabas traducir.'],
  ],
  q20: [
    ['span', 'No, nosotros no almacenamos ningún dato.'],
  ],
};

const IndonesianAnswer = {
  q1: [
    ['span', 'Tenang saja Sahabat U-Dictionary, kalau kamu mengalami masalah silakan mengirimkan detail kendala kamu berupa'],
    ['em', ' screenshot (tangkapan layar)/screen recording (rekaman layar) '],
    ['span', 'ditambah dengan'],
    ['em', ' merk dan tipe HP '],
    ['span', 'yang kamu gunakan ya ke email '],
    ['a', 'mailto:udictionary01@gmail.com'],
    ['span', '. Cheers!'],
  ],
  q2: [
    ['p', 'Hai Sahabat U-Dictionary, untuk cara aktifkan Magic translate adalah sebagai berikut ya:'],
    ['ol', [
      ['li', '1. Update ke versi terbaru'],
      ['li', '2. Aktifkan magic translate di setting aplikasi (gambar orang - pengaturan - terjemahan ajaib - izinkan)'],
      ['li', '3. Pergi ke Setting Hp - Additional Setting - Accessibility - aktifkan U-Dictionary.'],
    ],
    ],
    ['br'],
    ['span', 'Atau kamu bisa juga ikuti langkah - langkah di video ini ya '],
    ['a', 'https://www.youtube.com/watch?v=AMM5Hvm6cdo'],
  ],
  q3: [
    ['span', 'Hai Sahabat U-Dictionary, hal itu terjadi karena ketika kamu mengaktifkan aksesibilitas, kamu juga memberikan izin kepada '],
    ['strong', 'Talkback'],
    ['span', ' (Talkback bukan bagian dari U-Dictionary) secara tidak sengaja sehingga kendala yang kamu alami dapat terjadi ya. Kamu hanya perlu kembali ke Pengaturan dan menonaktifkan kembali '],
    ['strong', 'Talkback'],
    ['span', ' dan semuanya akan kembali normal ya! Untuk cara menonaktifkan Talkback, kamu bisa ikuti langkah-langkah berikut ya.'],
    ['br'],
    ['ol', [
      ['li', '1. Pergi ke Pengaturan (Settings)'],
      ['li', '2. Cari Pengaturan tambahan/lainnya (Additional Settings)'],
      ['li', '3. Lalu temukan Talkback dan nonaktifkan'],
    ]],
  ],
  q4: [
    ['p', 'Hai Sahabat U-Dictionary, karena aplikasi U-Dictionary itu bentuknya freemium yang berarti ada fitur yang hanya bisa digunakan oleh pengguna premium. Tetapi kamu tenang saja, apabila kamu sudah kehabisan limit magic translate pada hari ini, kamu cukup datang kembali besok dan limit kamu akan terisi kembali (bisa kembali digunakan secara terbatas). Atau kamu juga bisa berlangganan ya!'],
  ],
  q5: [
    ['span', 'Hai Sahabat U-Dictionary, untuk bisa menggunakan U-Dictionary secara offline, kamu perlu mengunduh '],
    ['strong', 'paket offline-nya'],
    ['span', ' terlebih dahulu ya. Untuk menemukan paket offline yang kamu butuhkan, kamu cukup ke Tab ‘Milikku’ dan pilih ‘Paket Offline’ untuk mengunduh paket offline yang kamu butuhkan dan kamu bisa mengaksesnya nanti. Dalam menu paket offline tersebut terdapat beberapa pilihan paket offline yang bisa kamu pilih sesuai kebutuhan ya.'],
  ],
  q6: [
    ['p', 'Hai Sahabat U-Dictionary, ada beberapa kemungkinan yang dapat terjadi pada saat Camera Translate tidak dapat menerjemahkan dengan baik.'],
    ['ol', [
      ['li', '1. Sudut foto kurang sesuai (tidak jelas)'],
      ['li', '2. Kurangnya sumber cahaya'],
      ['li', '3. Tulisan yang difoto kurang terbaca dengan jelas (tidak rapi)'],
    ]],
    ['p', 'Jadi untuk menghindari hal tersebut terulang kembali, silakan mengambil foto sesuai dengan arahan aplikasi kami ya (lurus, jelas, sesuai dengan kotak terjemah).'],
    ['p', 'Juga mohon diperhatikan untuk yang harus menggunakan mode horizontal, pastikan layar kamera kamu juga sudah horizontal. Tambahan untuk tulisan tangan juga pastikan tulisannya rapi ya!'],
  ],
  q7: [
    ['p', 'Hai Sahabat U-Dictionary, silakan coba langkah-langkah di bawah ini ya untuk dapat kembali menggunakan fitur Magic Translate dengan baik.'],
    ['ol', [
      ['li', '1. Pergi ke Pengaturan, lalu ke Aksesibilitas, matikan izin U-Dictionary dan nyalakan kembali.'],
      ['li', '2. Apabila langkah (i) tidak membantu, silakan coba ‘Tutup Paksa’ atau Force Close U-Dictionary kamu.'],
      ['li', '3. Apabila langkah (i) dan (ii) masih tidak membantu, silakan nyalakan ulang (restart) ponsel Anda.'],
    ]],
  ],
  q8: [
    ['p', 'Hai Sahabat U-Dictionary, untuk dapat mengakses Magic Translate meski ketika kamu sedang tidak membuka U-Dictionary, kamu dapat mencoba langkah-langkah berikut ya.'],
    ['ol', [
      ['li', '1. Pergi ke Pengaturan (Settings), lalu ke Aksesibilitas, lalu berikan izin ke U-Dictionary'],
      ['li', '2. Atau kamu juga dapat mencoba untuk mengklik agak lama pada ikon U-Dictionary, pilih App Info, lalu reaktivasi ‘Display over other Apps’'],
    ]],
    ['p', 'Perlu diperhatikan bahwa pengaturan ini dapat berbeda-beda pada setiap jenis ponsel ya!'],
  ],
  q9: [
    ['p', 'Hai Sahabat U-Dictionary, untuk ukuran aplikasi kami itu hanya 21MB kok. Apabila telah memakan terlalu banyak ruang, kamu boleh mencoba untuk menghapus cache file ya!'],
  ],
};

const ArabicAnswer = {
  q1: [
    ['p', 'تطبيق يوديكشناري هو تطبيق للترجمة وتعّلم اللغات المختلفة. يتوفر به مميزات كثيرة لا مثيل لها فى تطبيقا ترجمة أُخرى.'],
    ['p', 'يو ديكشناري يحتوي على ترجمات لـ 108 لغة. إذا قمت بالبحث عن كلمة سيظهر لك التبيق ترجمتها فى سياقات جمل مختلفة وأمثلة عديدة. يتوفر به ترجمات قواميس أٌخرى مثل قاموس اكسفورد.'],
    ['p', 'يو ديكشناري يحتوى على أداة للترجمة الصوتية لاي محادثة مع اجانب.'],
    ['p', ' يمكنك ايضا الترجمة بالتصوير من خلال ادة ترجمة الكاميرا فستستطيع معرفة ترجمة الكتابة الموجودة على أي ملف او منتج او لافته.'],
    ['p', 'يتميز تطبيق يو ديكشناري أدة الترجمة السحرية التى لا تتوفر فى أي تطبيق آخر والتى توفر للمستخدمين ترجمة المحاداثات و الإيميلات و الرسائل بكل سهولة ويُسر دون الحاجة لنسخ الص أو الرسالة والخروج من المحادثة '],
    ['p', 'وإلصاقها فى تطبيق آخر. وأنت داخل المحادثة كل ما عليك سحب رمز الترجمة السحرية وهى علامة العدسة'],
    ['p', 'وتركها فوق الرسالة او النص المراد ترجمته سيقوم التطبيق بسحره وإظهار الترجمة لك على الفور. '],
    ['p', 'يحتوى يوديكشناري على محتوي تعليم انجلش متنوع و ادة تصحيح اخطاء قواعد اللغة.'],
  ],
  q2: [
    ['p', 'لإستخدامات الترجمة الكتابية Text translation فأنت لا تحتاج لتوافر شبكة أنترنت يمكنك تحميل حزمة الترجمة '],
    ['p', 'بدون انترنت وترجمة 58 لغة مختلفة بالمرادفات وأمثلة الجمل وتمتع بترجمة صحيحة وانت غير متصل بشبكة'],
    ['p', 'انترنت'],
    ['p', 'لتحميل حزمة الترجمة بدون انترنت:'],
    ['p', 'قم بفتح التطبيق واضغط على الشخصي على يسار اسفل الشاشة الرئيسية فى التطبيق'],
    ['p', 'اختار اللغة من وإلي'],
    ['p', 'قم بتحميل الحزمة كاملة بالضغط على علامة التحميل الموجودة على أعلي الشاشة على اليسار'],
    ['p', 'أو قم بأختيار مفضلاتك من القائمة وقم بتحميلها'],
  ],
  q4: [
    ['p', 'بعد القيام بالدخول الى التطبيق ابدأ في الاستخدام.'],
    ['p', 'و لإستخدام الترجمة السحرية اضغط على رمز الـ U فى صندوق الترجمة. ثم قم بالضغط على زر التفعيل الموجود '],
    ['p', 'علي يسار الشاشة ثم قم بتحديد اللغات الرئيسية الخاصة بك من وإلي. الآن يمكنك استخدام اداة الترجمة السحرية قم'],
    ['p', 'بسحب علامة العدسة وتركها فوق النص المراد ترجمته وسيظهر لك الترجمة.'],
  ],
  q5: [
    ['p', 'يمكنك التمتع بخاصية الترجمة السحرية مجاناً خلال اليوم وإذا نفذت منك عدد المرات المحددة لليوم الواحد فإذن'],
    ['p', 'يمكنك الاشتراك فى يو ديكشناري برو او الانتظار ليوم جديد برصيد جديد من الترجمة السحرية المجانية.'],
  ],
  q6: [
    ['p', 'تطبيق يو ديكشناري للترجمة وتعلّم اللغات يوفر لك خاصية الترجمة داخل التطبيقات فمن الآن ليس عليك نسخ'],
    ['p', 'الكلام وإلصاقة فى مواقع أخرى خارج المحادثة كل ما عليك ان '],
    ['ul', [
      ['li', ' تقوم بتحميل تطبيق يو ديكشناري '],
      ['li', 'ثم افتح الأوذونات للتطبيقات التى تريد الترجمة من خلالها '],
      ['li', 'وبعد التفعيل سيظهر لك ايقونة الترجمة السحرية '],
      ['li', 'اختر اللغة من وإلى'],
      ['li', 'سيظهر رمز الترجمة السحرية يسار الشاشة قم بسحبها وتركها فوق النص او الرسالة المراد ترجمتها'],
    ]],
    ['p', ' وسيقوم التطبيق بسحره ويترجمه على الفور بكافة اللغات'],
  ],
  q7: [
    ['p', 'إذا كنت لا تستطيع استخدام الترجمة السحرية فسيكون سبب ذالك انك تخطيت الرصيد المحدد لاستخدام اداة الترجمة '],
    ['p', 'السحرية فى اليوم الواحد. يمكنك الإشتراك فى يوديكشنارى برو بإشتراك شهرى أو اسبوعي لكي تستطيع استخدام'],
    ['p', 'الترجمة السحرية بلا حدود.'],
    ['p', 'وإذا كنت استخدمتها عدد اقل من الرصيد المتاح ولكنها لا تعمل فقد يكون العطل تقنى يمكنك غلق و إعادة تشغيل'],
    ['p', 'هاتفك للتفعيل مجددا.'],
    ['p', 'إذا قمت بذالك والترجمة السحرية مازالت لا تعمل نرجو التواصل معنا عبر الايميل'],
    ['a', 'mailto:arabic@u-dictionary.com'],
    ['p', 'او قم برفق لقطة شاشة وتسجيل المشكلة عبر التطبيق من خلال الضغط على الشخصي ثم اضغط على رأيك ثم'],
    ['p', 'اختار نوع المشكلة واكتب بياناتك واكتب تفاصيل المشكلة وسوف نتلقي العطل أو المشكلةونقوم بحلها على الفور.'],
  ],
  q8: [
    ['p', 'قم بفتح تطبيق يو ديكشناري ثم اضغط على رمز الكاميرا الموجود اسفل صندوق البحث'],
    ['p', 'قم بتحديد اللغة المراد ترجمتها من و إلى من أعلي الشاشة'],
    ['p', 'وّجه الكاميرا اتجاه الكلام المراد ترجمته وألتقط صورة'],
    ['p', 'قم بقص الصورة وتحديد الجزء المراد ترجمته'],
    ['p', '  اضغط زر ترجمة وسيظهر لك نتيجة البحث'],
  ],
  q9: [
    ['p', 'خاصية ترجمة الأشياء داخل أداة ترجمة الكاميرا متاحة فقط لهواتف الأبل فقط نظام الـ IOS. وترجمة الأشياء يٌتيح لك معرفة اسماء وكلمات الاشياء الموجودة حولك بلغتين مختلفين وسماع نطقهما ايضا.'],
  ],
  q10: [
    ['span', 'يمكنك الإبلاغ عن عطل او خطأ او مشكلة تواجهك فى استخدامك لتطبيق يو ديكشناري من خلال إرسال تفاصيل'],
    ['span', 'المشكلة مع إرفاق لقطة شاشة للمشكلة فى بريد إلكترونى وترسله على عنوان'],
    ['a', 'mailto:arabic@u-dictionary.com'],
    ['span', 'او يمكنك إرسال تفاصيل المشكلة من خلال التطبيق قم بفتح التطبيق و اختار من اسفل الشاشة الشخصي ثم اضغط '],
    ['span', 'على رأيك واختار نوع المشكلة وقم بملئ بياناتك لنستطيع التواصل معك بعد حل المشكلة او العُطل واكتب تفاصيل الشكلة ثم اضغط على إرسال.'],
  ],
  q11: [
    ['p', 'يمكنك التمتع بإستخدام تطبيق يو ديكشناري بدون إعلانات من خلال الترقية إلى يو ديكشناري برو'],
    ['p', 'قم بفتح التطبيق و أختار الشخصي من الايكونة الموجودة على يسار أسفل الصفحة الرئيسية اختار إبدأ الموجودة أمام جرب مجاناً.'],
  ],
  q12: [
    ['p', 'لتفعيل الأذونات لتطبيق يو ديكشناري ليصل إلى التطبيقات الأٌخري ويستطيع الترجمة بكل سهولة قم بفتح الخدمات التي تم تنزيلها من اعدادات هاتفك'],
    ['ol', [
      ['li', 'عندما تذهب إلى صفحة إمكانية الوصول ، يمكنك العثور على الخدمات التي تم تنزيلها في البداية.'],
      ['li', ' قم بتشغيل يو ديكشناري في الخدمات التي تم تنزيلها.'],
    ]],
    ['p', 'ملحوظة 1: قد تحتاج إلى التمرير لأعلى للعثور على يو ديكشناري أو العثور عليه في المزيد من الخدمات التي تم تنزيلها.'],
    ['p', 'ملحوظة 2: لا تقم بتشغيل TalkBack والتكبير عن طريق الخطأ.'],
  ],
  q13: [
    ['p', 'قم بسحب الشاشة لأعلى اضغط على اللغة الموجودة على اليسار واختار من القائمة اللغة اللتى تريدها ثم الضغط على الرز الذى فى اليمين وقم بإختيار اللغة الأٌخرى.'],
  ],
  q14: [
    ['p', 'خاصية شاشة غلق الكلمة هي خاصية مميزة فى تطبيق يو ديكشناري وتتعمد أن تٌعلمك حصيلة كلمات جديدة كل يوم يمكنك سماع نطق الكلمة دون الحاجة لفتح التطبيق او حتى فتح شاشة الهاتف. '],
    ['br'],
    ['p', 'للإلغاء:'],
    ['p', 'قم بقتح تطبيق يو دشكناري'],
    ['p', 'اضغط على الشخصي الموجودة فى يسار اسفل الصفحة الرئيسية فى التطبيق'],
    ['p', 'اضغط على شاشة غلق الكلمة'],
    ['p', 'قم بالضغط على زر التفعيل الموجود فى أعلي يسار الصفحة'],
  ],
}

const langAnswer = {
  English: EnglishAnswer,
  Español: SpanishAnswer,
  Bahasa: IndonesianAnswer,
  العربية: ArabicAnswer,
}
export default {
  langAnswer,
}
