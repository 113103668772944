<!--
* FAQ 组件: question
* 功能：用于 FAQ 中单个问题的问答模式切换。
-->
<template lang="pug">
.question_panel
  .box
    .question
      .desc
        p {{this.question}}
      .img
        img.arrow(src="@/assets/images/faq/arrow.png")
    .answer
</template>
<script>
export default {
  name: 'question',
  data() {
    return {
      question: 'test12312434kjhdla;sdkashgdhajkldsjhdgsahjkdjasnbdad',
      answer: 'here is answer!',
    }
  },
}
</script>
<style lang="stylus" scoped>
.question_panel
  position absolute
  left 50%
  top 50%
  transform translate(-560px, -312px)
  margin-top 50px
  .box
    width 1120px
    height 624px
    background #FFFFFF
    .question
      display flex
      align-items center
      .desc
      .arrow
        width 14px
</style>
